import * as React from 'react'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDInput from 'components/MDInput'
import Button from '@mui/material/Button'
import SendIcon from '@mui/icons-material/Send'
import { db } from '../../../firebase'
import { collection, onSnapshot } from 'firebase/firestore'

function UserData() {
  const [data, setData] = React.useState([])
  const [userDataLoading, setUserDataLoading] = React.useState(true)
  const [notificationMessages, setNotificationMessages] = React.useState({})

  React.useEffect(() => {
    const fetchData = onSnapshot(
      collection(db, 'users'),
      (snapshot) => {
        const userList = snapshot.docs.map((items) => {
          return { id: items.id, ...items.data() }
        })
        setUserDataLoading(false)
        setData(userList)
      },
      (error) => {
        console.log('error == ', error)
      }
    )
    return () => {
      fetchData()
    }
  }, [notificationMessages])

  const SR_NO = ({ srNo }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography variant="body2" fontWeight="small">
          {srNo}
        </MDTypography>
      </MDBox>
    </MDBox>
  )

  const handleNotificationChange = (userId, value) => {
    setNotificationMessages((prevMessages) => ({
      ...prevMessages,
      [userId]: value,
    }))
  }

  return {
    userDataLoading,
    columns: [
      { Header: 'NO#', accessor: 'srNo', width: '10%', align: 'left' },
      { Header: 'Email', accessor: 'email', align: 'left' },
      { Header: 'Phone', accessor: 'phone', align: 'left' },
      { Header: 'Message', accessor: 'message', align: 'left' },
      { Header: 'Action', accessor: 'action', align: 'right' },
    ],
    rows: [
      ...data.map((user, index) => {
        const userId = user.id
        return {
          srNo: <SR_NO srNo={index + 1} />,
          email: user.email,
          phone: user.phone,
          message: (
            <MDInput
              sx={{
                width: '100%',
              }}
              label="Message"
              multiline
              focused
              value={notificationMessages[userId] || ''}
              rows={3}
              onChange={(e) => {
                handleNotificationChange(userId, e.target.value)
              }}
            />
          ),
          action: (
            <Button
              variant="contained"
              color="success"
              startIcon={<SendIcon />}
              sx={{
                '&:hover': {
                  backgroundColor: '#c7f2e5',
                },
              }}
            >
              Send
            </Button>
          ),
        }
      }),
    ],
  }
}

export default UserData
