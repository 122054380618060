// Admin panel React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import { Link } from 'react-router-dom'

import * as React from 'react'

// fire store
import { db } from '../../../firebase'
import { collection, onSnapshot } from 'firebase/firestore'

function UserData() {
  const [data, setData] = React.useState([])
  const [userDataLoading, setUserDataLoading] = React.useState(true)

  React.useEffect(() => {
    const fetchData = onSnapshot(
      collection(db, 'users'), // GET ALL USERS
      (snapshot) => {
        const userList = snapshot.docs.map((items) => {
          return { id: items.id, ...items.data() }
        })
        setUserDataLoading(false)
        setData(userList)
      },
      (error) => {
        console.log('error == ', error)
      }
    )
    return () => {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const SR_NO = ({ srNo }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography variant="body2" fontWeight="small">
          {srNo}
        </MDTypography>
      </MDBox>
    </MDBox>
  )

  return {
    userDataLoading,
    columns: [
      { Header: 'NO#', accessor: 'srNo', width: '10%', align: 'left' },
      { Header: 'uid', accessor: 'id', align: 'left' },
      { Header: 'Is Online', accessor: 'isOnline', align: 'left' },
      { Header: 'Email', accessor: 'email', align: 'left' },
      { Header: 'Badge Count', accessor: 'badgeCount', align: 'left' },
      {
        Header: 'Location Longitude',
        accessor: 'location.longitude',
        align: 'left',
      },
      {
        Header: 'Location Latitude',
        accessor: 'location.latitude',
        align: 'left',
      },
      {
        Header: 'Profile Pic',
        accessor: 'profilePictureURL',
        align: 'left',
      },
      {
        Header: 'Id Card',
        accessor: 'idCardURL',
        align: 'left',
      },
      {
        Header: 'Selfie',
        accessor: 'selfieURL',
        align: 'left',
      },
      { Header: 'First Name', accessor: 'firstName', align: 'left' },
      { Header: 'Username', accessor: 'username', align: 'left' },
      { Header: 'Last Name', accessor: 'lastName', align: 'left' },
      { Header: 'Sign Up Location', accessor: 'signUpLocation', align: 'left' },
      { Header: 'Phone', accessor: 'phone', align: 'left' },
      { Header: 'Action', accessor: 'action', align: 'right' },
    ],
    rows: [
      ...data.map((user, index) => {
        return {
          srNo: <SR_NO srNo={index + 1} />,
          id: user.id,
          isOnline: user.isOnline.toString(),
          email: user.email,
          badgeCount: user.badgeCount,
          locationLongitude: user.location.longitude,
          locationLatitude: user.location.latitude,
          profilePictureURL: user.profilePictureURL,
          idCardURL: user.idCardURL,
          selfieURL: user.selfieURL,
          firstName: user.firstName,
          username: user.username,
          lastName: user.lastName,
          signUpLocation: user.signUpLocation,
          phone: user.phone,
          action: (
            <Link to={`/admin/users/detail/${user.id}`}>
              <MDButton variant="gradient" color="info" size="small">
                Detail
              </MDButton>
            </Link>
          ),
        }
      }),
    ],
  }
}
export default UserData
