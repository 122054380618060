import { serverTimestamp } from 'firebase/firestore'
import { auth, db } from '../firebase'
import { ErrorCode } from './ErrorCode'

export const registerUser = (userDetails, appIdentifier) => {
  const usersRef = db.collection('users')
  const {
    email,
    firstName,
    lastName,
    username,
    password,
    phone,
    profilePictureURL,
    location,
    signUpLocation,
  } = userDetails

  return new Promise(function (resolve, _reject) {
    auth()
      .createUserWithEmailAndPassword(email, password)
      .then((response) => {
        const timestamp = serverTimestamp()
        const uid = response.user.uid

        const data = {
          id: uid,
          userID: uid, // legacy reasons
          email,
          firstName,
          lastName,
          username,
          phone: phone || '',
          profilePictureURL,
          location: location || '',
          signUpLocation: signUpLocation || '',
          appIdentifier,
          createdAt: timestamp,
          preferredLanguage: 'english',
          idCardURL: '',
          selfieURL: '',
          IDVerified: false,
        }

        usersRef
          .doc(uid)
          .set(data)
          .then(() => {
            resolve({ user: data })
          })
          .catch((error) => {
            alert(error)
            resolve({ error: ErrorCode.serverError })
          })
      })
      .catch((error) => {
        var errorCode = ErrorCode.serverError

        if (error.code === 'auth/email-already-in-use') {
          errorCode = ErrorCode.emailInUse
        }

        resolve({ error: errorCode })
      })
  })
}
