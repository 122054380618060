import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getMessaging } from 'firebase/messaging'

// Initialize Firebase
const app = initializeApp({
  apiKey: 'AIzaSyCmaG_Pw8I8V1hw9jANhDG_Q2bwGmpuAuI',
  authDomain: 'shondhi-bd.firebaseapp.com',
  projectId: 'shondhi-bd',
  storageBucket: 'shondhi-bd.appspot.com',
  messagingSenderId: '242843357319',
  appId: '1:242843357319:web:85536821d9765bcceba822',
  measurementId: 'G-PLGDN2SL8K',
})

export const auth = getAuth(app)
export const db = getFirestore(app)
export const storage = getStorage(app)
export const messaging = getMessaging(app)
