// @mui material components
import Grid from '@mui/material/Grid'

//  admin panel React components
import MDBox from 'components/MDBox'

//  admin panel React example components
import * as React from 'react'
import DashboardLayout from 'common/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'common/Navbars/DashboardNavbar'
import { CircularProgress } from '@mui/material'
import { green } from '@mui/material/colors'
import Footer from 'common/Footer'
import { Typography, Paper, Divider } from '@mui/material'
import DataTable from 'layouts/notifications/tables'
// Data
import usersTable from 'layouts/notifications/data/notificationTable'

function Notifications() {
  const { columns, rows, userDataLoading } = usersTable()

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={9} lg={12} mx={'auto'}>
              <Paper
                elevation={5}
                sx={{
                  bgcolor: 'azure',
                  margin: '0, auto',
                  borderRadius: '1.5rem',
                  width: '100%',
                  p: 2,
                }}
              >
                <Typography
                  variant="h3"
                  color="secondary.main"
                  sx={{ pt: 2, textAlign: 'center' }}
                >
                  Send Notifications
                </Typography>
                <Divider />
                {userDataLoading && (
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="25vh"
                  >
                    <CircularProgress size={30} sx={{ color: green[500] }} />
                  </MDBox>
                )}
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={true}
                    noEndBorder
                    loading={userDataLoading}
                    canSearch={true}
                  />
                </MDBox>
              </Paper>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  )
}

export default Notifications
