// @mui material components
import Grid from '@mui/material/Grid'
import { Box, TextField, Button } from '@mui/material'

// Admin panel React components
import MDBox from 'components/MDBox'
import Card from '@mui/material/Card'
import MDTypography from 'components/MDTypography'

// Firebase and register function
import * as React from 'react'
import { registerUser } from '../../helpers/util'
import DashboardLayout from 'common/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'common/Navbars/DashboardNavbar'
import Footer from 'common/Footer'

function AddNewUser() {
  const [newUser, setNewUser] = React.useState({
    name: '',
    email: '',
    password: '',
    age: '23',
    appIdentifier: 'rn-dating-android',
    badgeCount: 0,
    cannabis: 'never',
    drinking: 'occasionally',
    firstName: 'test',
    id: 'u1frNP1mL5c1CAIht66VZniMqjN2',
    isOnline: false,
    isVIP: false,
    lastName: 'user',
    location: {
      latitude: 40.761619,
      longitude: -73.7967513,
    },
    phone: '+15104705368',
    position: {
      latitude: 40.761619,
      longitude: -73.7967513,
    },
    preferredLanguage: 'english',
    profession: 'Manager',
    profilePictureURL:
      'https://shondhi-assets.s3.eu-central-1.amazonaws.com/empty-avatar.jpg',
    pushKitToken: '',
    pushToken:
      'dN56OAEMRPu4nOPJM-AQnX:APA91bFDhT1LCb9vEB37JpqOb55_KdN-gm5QZWOILvQsyilKYdVgYghhN5n9b9ynVVbjkhyY9muLv5w6GuO_eKF46rdM9VOf2DgM75Xvh-K7TxXJLYhR0BjBpP0cSrioCh0cqmyG8CAJ',
    religious_preference: 'baháʼí',
    school: 'Bangla college',
    selfieURL:
      'https://firebasestorage.googleapis.com/v0/b/shondhi-bd.appspot.com/o/selfies%2FIMG_20231112_003708.jpg?alt=media&token=0a365dba-1b57-4a5c-ac3e-b0c97336414e',
    settings: {
      distance_radius: 'unlimited',
    },
    gender: 'none',
    gender_preference: 'all',
    show_me: true,
    signUpLocation: '',
    smoking: 'occasionally',
    userID: 'u1frNP1mL5c1CAIht66VZniMqjN2',
    username: 'layla101',
  })

  const handleAddUser = () => {
    // Assuming you have the appIdentifier available
    const appIdentifier = 'rn-dating-android' // replace with your actual appIdentifier

    // Call the register function to create a new user
    registerUser(newUser, appIdentifier).then((result) => {
      if (result.error) {
        // Handle errors
        console.error(result.error)
        // You can add UI feedback for the user here
      } else {
        // User registration successful
        console.log('User registered successfully:', result.user)
        // You can redirect or show a success message to the user
      }
    })
  }

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <MDBox pt={0} px={2}>
                    <MDTypography
                      variant="h6"
                      fontWeight="medium"
                      sx={{ textAlign: 'center' }}
                    >
                      Add User Details Below
                    </MDTypography>
                  </MDBox>
                </Card>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': {
                      m: 2,
                      maxWidth: '100%',
                      display: 'flex',
                      direction: 'column',
                      justifyContent: 'center',
                    },
                    textAlign: 'center', // Center align the form elements
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    label="Name"
                    type="text"
                    color="secondary"
                    required
                    value={newUser.name}
                    onChange={(e) =>
                      setNewUser({
                        ...newUser,
                        name: e.target.value,
                      })
                    }
                  />
                  {/* Add other fields here */}
                  <TextField
                    label="Email"
                    type="email"
                    color="secondary"
                    required
                    value={newUser.email}
                    onChange={(e) =>
                      setNewUser({
                        ...newUser,
                        email: e.target.value,
                      })
                    }
                  />
                  <TextField
                    label="Password"
                    type="password"
                    color="secondary"
                    required
                    value={newUser.password}
                    onChange={(e) =>
                      setNewUser({
                        ...newUser,
                        password: e.target.value,
                      })
                    }
                    helperText={
                      newUser.password.length < 6
                        ? 'Minimum 6 characters required'
                        : ''
                    }
                  />
                  {/* Add other fields here */}
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleAddUser}
                    sx={{ mt: 2 }} // Add margin top to the button
                  >
                    Add User
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  )
}

export default AddNewUser
