// Material Dashboard 2 React layouts
import Dashboard from 'layouts/dashboard'
import Users from 'layouts/users'
import CheckId from 'layouts/checkId'
import NewUser from 'layouts/newUser'
import Notifications from 'layouts/notifications/Notifications'
import CreateAdmin from 'layouts/authentication/users/CreateAdmin'

//auth routes
import UsersDetail from 'layouts/users/components/Detail'

// @mui icons
import DashboardIcon from '@mui/icons-material/Dashboard'
import CreditScoreIcon from '@mui/icons-material/CreditScore'
import StoreIcon from '@mui/icons-material/Store'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import LoginIcon from '@mui/icons-material/Login'

import * as React from 'react'
import { Navigate } from 'react-router-dom'
import { useContext } from 'react'
import { AuthContext } from 'context/AuthContext'

const AdminAuthRoutes = ({ children }) => {
  const { role } = useContext(AuthContext)
  return role === 'admin' ? children : <Navigate to="/login" />
}

const routes = [
  {
    routeRole: 'admin',
    type: 'collapse',
    name: 'Dashboard',
    key: 'admin/dashboard',
    icon: <DashboardIcon />,
    route: '/admin/dashboard',
    component: (
      <AdminAuthRoutes>
        <Dashboard />
      </AdminAuthRoutes>
    ),
  },
  {
    routeRole: 'admin',
    type: 'collapse',
    name: 'Users',
    key: 'admin/users',
    icon: <StoreIcon />,
    route: '/admin/users',
    component: (
      <AdminAuthRoutes>
        <Users />
      </AdminAuthRoutes>
    ),
  },
  {
    routeRole: 'admin',
    type: 'collapse',
    name: 'CheckID',
    key: 'admin/checkId',
    icon: <CreditScoreIcon />,
    route: '/admin/checkId',
    component: (
      <AdminAuthRoutes>
        <CheckId />
      </AdminAuthRoutes>
    ),
  },
  {
    routeRole: 'admin',
    type: 'collapse',
    name: 'Add User',
    key: 'admin/addUser',
    icon: <CreditScoreIcon />,
    route: '/admin/createUser',
    component: (
      <AdminAuthRoutes>
        <NewUser />
      </AdminAuthRoutes>
    ),
  },
  {
    routeRole: 'admin',
    type: 'collapse',
    name: `Notifications`,
    key: 'admin/notifications',
    icon: <NotificationsActiveIcon />,
    route: '/admin/notifications',
    component: (
      <AdminAuthRoutes>
        <Notifications />
      </AdminAuthRoutes>
    ),
  },
  {
    routeRole: 'admin',
    type: 'collapse',
    name: 'CreateAdmin',
    icon: <LoginIcon />,
    route: '/admin/create-admin',
    component: (
      <AdminAuthRoutes>
        <CreateAdmin />
      </AdminAuthRoutes>
    ),
  },
]

const authRoutes = [
  {
    routeRole: 'admin',
    type: 'authRoutes',
    route: '/admin/users/detail/:id',
    component: (
      <AdminAuthRoutes>
        <UsersDetail />
      </AdminAuthRoutes>
    ),
  },
]
export default routes
export { authRoutes }
