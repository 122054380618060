// @mui material components
import Grid from '@mui/material/Grid'

// Admin panel React components
import MDBox from 'components/MDBox'
import Card from '@mui/material/Card'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import Icon from '@mui/material/Icon'

// Admin panel React example components
import * as React from 'react'
import DashboardLayout from 'common/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'common/Navbars/DashboardNavbar'
import Footer from 'common/Footer'
import DataTable from 'common/Tables/DataTable'

// Data
import usersTable from 'layouts/users/data/usersTable'

function Users() {
  const { columns, rows, userDataLoading } = usersTable()

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDBox
                      pt={2}
                      pb={2}
                      px={2}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <MDTypography
                        variant="h6"
                        fontWeight="medium"
                        color="white"
                      >
                        All USERS
                      </MDTypography>
                      <MDButton
                        variant="gradient"
                        color="light"
                        onClick={() => {}}
                      >
                        <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
                        &nbsp;ADD USER
                      </MDButton>
                    </MDBox>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={true}
                      noEndBorder
                      loading={userDataLoading}
                      canSearch={true}
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  )
}

export default Users
