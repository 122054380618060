// Admin panel React components
import MDButton from 'components/MDButton'
import * as React from 'react'

// Fire store
import { db } from '../../../firebase'
import { collection, onSnapshot, updateDoc, doc } from 'firebase/firestore'

function UserData() {
  const [data, setData] = React.useState([])
  const [userDataLoading, setUserDataLoading] = React.useState(true)

  React.useEffect(() => {
    const fetchData = onSnapshot(
      collection(db, 'users'), // GET ALL USERS
      (snapshot) => {
        const userList = snapshot.docs.map((items) => {
          return { id: items.id, ...items.data() }
        })
        setUserDataLoading(false)
        setData(userList)
      },
      (error) => {
        console.log('error == ', error)
      }
    )
    return () => {
      fetchData()
    }
  }, [])

  const handleVerifyToggle = async (userId, currentStatus) => {
    try {
      // Update the document with the toggled IDVerified status
      const userDocRef = doc(db, 'users', userId)
      await updateDoc(userDocRef, {
        IDVerified: !currentStatus,
      })
    } catch (error) {
      console.error('Error toggling IDVerified status:', error)
    }
  }

  return {
    userDataLoading,
    columns: [
      { Header: 'uid', accessor: 'id', align: 'left' },
      {
        Header: 'Profile Pic',
        accessor: 'profilePictureURL',
        align: 'left',
      },
      {
        Header: 'Id Card',
        accessor: 'idCardURL',
        align: 'left',
      },
      {
        Header: 'Selfie',
        accessor: 'selfieURL',
        align: 'left',
      },
      { Header: 'Verify', accessor: 'action', align: 'right' },
    ],
    rows: [
      ...data.map((user) => {
        return {
          id: user.id,
          profilePictureURL: user.profilePictureURL,
          idCardURL: user.idCardURL,
          selfieURL: user.selfieURL,
          action: (
            <MDButton
              variant="gradient"
              color={user.IDVerified ? 'success' : 'unVerified'}
              size="small"
              onClick={() => handleVerifyToggle(user.id, user.IDVerified)}
            >
              Mark As {user.IDVerified ? 'Unverified' : 'Verified'}
            </MDButton>
          ),
        }
      }),
    ],
  }
}

export default UserData
