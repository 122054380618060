// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types'

// @mui material components
import Menu from '@mui/material/Menu'

// Amdin panel React components
import MDBox from 'components/MDBox'

// Amdin panel React example components
import DefaultNavbarLink from 'common/Navbars/DefaultNavbar/DefaultNavbarLink'

function DefaultNavbarMobile({ open, close }) {
  const { width } = open && open.getBoundingClientRect()

  return (
    <Menu
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      anchorEl={open}
      open={Boolean(open)}
      onClose={close}
      MenuListProps={{ style: { width: `calc(${width}px - 4rem)` } }}
    >
      <MDBox px={0.5}>
        <DefaultNavbarLink
          icon="donut_large"
          name="dashboard"
          route="/admin/dashboard"
        />
      </MDBox>
    </Menu>
  )
}

// Typechecking props for the DefaultNavbarMenu
DefaultNavbarMobile.propTypes = {
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.object])
    .isRequired,
}

export default DefaultNavbarMobile
